<template>
  <section class="section is-main-section fullHeightSection">
    <div class="columns">
      <div class="column">
        <ContextTableCard
          title="Actions"
          icon="gesture-tap"
          :tableData="tableData"
          :header-icons="headerIcons"
          :sortField="search.sortField"
          :sortOrder="search.sortOrder"
          :page="search.page"
          :total="total"
          @refreshData="debounceRefreshData"
          @page-change="pageChange"
          @sort="sort"
          v-model="selected"
          :row-class="setActionColour"
          @contextDefaultClicked="editAction"
          :isLoading="isLoading"
        >
          <b-table-column label="Status" field="status" sortable v-slot="props">
            <div class="is-selected invertCellPadding" :class="setActionColour(props.row)">
              <b-icon :icon="icon(props.row.status)" size="is-small" /> {{ props.row.statusDesc }}
            </div>
          </b-table-column>
          <b-table-column label="Due" field="dueDate" sortable v-slot="props">
            {{ shortFormatDateTime(props.row.dueDateLocal) }}
          </b-table-column>
          <b-table-column label="Action" field="description" sortable v-slot="props">
            {{ props.row.description }}
          </b-table-column>

          <b-table-column label="Role" field="roleName" sortable v-slot="props">
            {{ props.row.roleName }}
          </b-table-column>
        </ContextTableCard>
      </div>
      <div class="column">
        <NotesList v-if="residentId" :residentId="residentId" />
      </div>
    </div>
  </section>
</template>

<script>
import NotesList from "@/components/assessments/NotesList.vue";
import ResidentMixin from "@/mixins/residentMixin";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";
import ActionStatus from "@/enums/actionStatus.js";
import ActionType from "@/enums/actionType.js";
import ActionDialog from "@/components/dialogs/ActionDialog.vue";

export default {
  mixins: [ResidentMixin, ContextTableMixin],
  components: {
    ContextTableCard,
    NotesList
  },
  data() {
    return {
      url: "residentactions",
      entity: "Actions",
      search: {
        page: 1,
        sortField: "dueDate",
        sortOrder: "asc",
        residentId: this.$store.getters["resident/residentId"],
        openOnly: true
      },
      contextOptions: [{ text: "Edit", event: "edit-action" }]
    };
  },
  methods: {
    setActionColour(row) {
      return ActionStatus.Colour[row.status] + "-selected";
    },
    icon(status) {
      return ActionStatus.Icon[status];
    },
    editAction() {
      this.$buefy.modal.open({
        parent: this,
        component: ActionDialog,
        hasModalCard: true,
        props: { risk: this.selected, action: this.selected, actionType: ActionType.Enum.CarePlan },
        events: { refresh: this.debounceRefreshData }
      });
    }
  }
};
</script>
